import React, { useCallback } from 'react';
import { useMatch, useNavigate, useResolvedPath } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Tooltip } from '@mui/material';

interface Props {
  to: string;
  icon: JSX.Element;
  title: string;
  open: boolean;
  isExternal?: boolean;
}

const NavigationLink: React.FC<Props> = ({
  to,
  icon,
  isExternal,
  title,
  open,
}) => {
  const navigate = useNavigate();
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  const handleOnClick = useCallback(() => {
    if (isExternal) {
      window.open(to, '_blank', 'noopener,noreferrer');
      return;
    }

    navigate(to);
  }, [navigate, isExternal, to]);

  return (
    <ListItemButton
      selected={Boolean(match)}
      onClick={handleOnClick}
      sx={{
        pl: 0,
        pt: 1,
        pb: 1,
        borderRadius: 1,
        pr: open ? 2 : 0,
        justifyContent: open ? 'flex-start' : 'center',
      }}
    >
      {open && (
        <>
          <ListItemIcon sx={{ justifyContent: 'center' }}>{icon}</ListItemIcon>
          <ListItemText primary={title} />
        </>
      )}
      {!open && (
        <Tooltip title={title} placement="right">
          <ListItemIcon sx={{ justifyContent: 'center' }}>{icon}</ListItemIcon>
        </Tooltip>
      )}
    </ListItemButton>
  );
};

export default NavigationLink;

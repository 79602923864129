import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import { collapsedDrawerWidth, drawerWidth } from '../../theme';
import logo from '../../assets/logo.svg';
import Navigation from '../navigation/Navigation';
import UserInfo from './components/userInfo/UserInfo';
import { UsersSearchBox } from '../../components';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';
import styled from '@emotion/styled';
import { SCREEN_WIDTH } from '../../components/finKitAvatar/breakpoints';
import { throttle } from 'lodash';

const Sidebar: React.FC = () => {
  const usersList = useAppSelector(userDataSelectors.getUsersList);
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const RockyImage = styled.img`
    width: 100%;
    ${!isMenuOpen &&
    `
    @media (max-width: ${SCREEN_WIDTH.DESKTOP13}px) {
      width: 60px;
      height: 50px;
      object-fit: none;
      object-position: top left;
    }`}
  `;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleWindowResize = useCallback(
    throttle(() => {
      setIsMenuOpen(window.innerWidth > SCREEN_WIDTH.DESKTOP13);
    }, 10),
    [setIsMenuOpen],
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleOpenClick = useCallback(() => {
    setIsMenuOpen(true);
  }, [setIsMenuOpen]);

  const toggleMenu = useCallback(() => {
    if (window.innerWidth < SCREEN_WIDTH.DESKTOP13) setIsMenuOpen(!isMenuOpen);
  }, [setIsMenuOpen, isMenuOpen]);

  return (
    <Drawer
      variant="permanent"
      anchor="left"
      PaperProps={{ elevation: 1 }}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: isMenuOpen ? drawerWidth : `${collapsedDrawerWidth}px`,
          boxSizing: 'border-box',

          transition: (theme) =>
            theme.transitions.create(['width'], {
              easing: theme.transitions.easing.easeInOut,
              duration: '0.25s',
            }),

          pt: 1.25,
          border: 'none',
          borderRadius: 0,
        },
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: 'inline-flex',
          justifyContent: isMenuOpen ? 'center' : 'flex-start',
          pr: isMenuOpen ? 0 : 0.25,
          alignItems: 'center',
        }}
      >
        <Link to="#" onClick={toggleMenu}>
          <RockyImage src={logo} alt="Rocky Finance" />
        </Link>
        {isMenuOpen && <UserInfo />}
      </Grid>

      <Grid container spacing={1} pt={1} pl={1} pr={1} pb={0}>
        {usersList && !!usersList.length && (
          <Grid item xs={12}>
            <UsersSearchBox
              options={usersList || []}
              handleOpenClick={handleOpenClick}
              open={isMenuOpen}
            />
          </Grid>
        )}
      </Grid>
      <Navigation open={isMenuOpen} />
    </Drawer>
  );
};

export default Sidebar;
